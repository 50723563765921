<template>
  <UiDialog :open="props.open">
    <UiDialogContent
      :class="'max-h-full lg:max-h-[90%] overflow-hidden flex lg:w-[60%] w-full'"
    >
      <UiDialogHeader :class="'max-h-[20%]'">
        <slot name="header" />
      </UiDialogHeader>
      <div class="flex h-auto overflow-auto p-4">
        <slot name="content" />
      </div>
    </UiDialogContent>
  </UiDialog>
</template>

<script setup lang="ts">
const props = defineProps<{ open: boolean }>();
</script>

<style scoped></style>
