<template>
  <UiDialogTitle
    :class="'text-bold-22 relative !text-iq-white p-6 items-end border-b border-b-iq-white justify-between flex'"
    ><div class="truncate text-medium-18 tracking-iq-wide max-w-[90%]">
      {{ title }}
    </div>

    <Icon
      class="icon-iq text-iq-red"
      name="heroicons:x-mark-16-solid"
      @click="emit('modal:close')"
    />
  </UiDialogTitle>
</template>

<script setup lang="ts">
const emit = defineEmits<{
  (event: 'modal:close'): void;
}>();

defineProps<{
  title: string;
}>();
</script>
