<script setup lang="ts">
import { cn } from '@/lib/utils';
import {
  DialogContent,
  DialogOverlay,
  DialogPortal,
  useEmitAsProps,
  type DialogContentEmits,
  type DialogContentProps,
} from 'radix-vue';

const props = defineProps<DialogContentProps & { class?: string }>();
const emits = defineEmits<DialogContentEmits>();

const emitsAsProps = useEmitAsProps(emits);
</script>

<template>
  <DialogPortal>
    <DialogOverlay
      class="fixed inset-0 z-50 bg-iq-black/20 backdrop-blur-sm data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0"
    />
    <DialogContent
      :class="
        cn(
          'fixed left-[50%] top-[50%] focus-visible:outline-none z-[10000] flex flex-col w-[100%] md:max-w-[95%]  translate-x-[-50%] translate-y-[-50%] bg-iq-black  shadow-lg duration-200 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[state=closed]:slide-out-to-left-1/2 data-[state=closed]:slide-out-to-top-[48%] data-[state=open]:slide-in-from-left-1/2 data-[state=open]:slide-in-from-top-[48%] rounded-[18px] md:w-full',
          props.class
        )
      "
      v-bind="{ ...props, ...emitsAsProps }"
    >
      <slot />
    </DialogContent>
  </DialogPortal>
</template>
